import { ExitModal } from 'packs/ExitModal.min'

$(() => {
  const isLanding = () => {
    if (document.referrer === '') {
      return true
    } else {
      const url = new URL(document.referrer)
      const internalHosts = [
        'localhost',
        'tas-reno.com',
        'stg.tas-reno.com',
        'renobank.jp',
        'stg.renobank.jp'
      ]
      if (internalHosts.includes(url.hostname)) {
        return false
      } else {
        return true
      }
    }
  }

  if (!isLanding()) return

  new ExitModal({
    modalSelector: '#renobankExitModal',
    onBeforeOpen: () => {
      dataLayer.push({
        event: 'send-ga4-event',
        'ga4_event-event_name': 'exit_modal_open'
      })
    },
    onBeforeClose: () => {
      dataLayer.push({
        event: 'send-ga4-event',
        'ga4_event-event_name': 'exit_modal_close'
      })
    }
  }).run()

  // AI査定へのリンクをクリックした際のGA4イベント送信
  $('.renobankExitModalWrapCta').on('click', () => {
    dataLayer.push({
      event: 'send-ga4-event',
      'ga4_event-event_name': 'exit_modal_cta_click'
    })
  })
})
